

.iconNSRE-lighthouse-new::before{
	background: url('/shared/assets/images/nassau-black-stack.svg') no-repeat !important;
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    background-size: contain !important;
    top: 30px !important;
    position: absolute;
}

[data-icon]:before {
	content: attr(data-icon)
}

[class*=" iconNSRE-"],[class^=iconNSRE-],[data-icon]:before {
	display: inline-block;
	font-family: "nsre";
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.iconNSRE-lighthouse:before {
	content: "\e900"
}

.iconNSRE-nassaure-long:before {
	content: "\e901"
}

.iconNSRE-nassaure:before {
	content: "\e902"
}

.NSRE-form-question {
	padding-left: 1.5em;
	text-indent: -1em
}

.NSRE-form {
	font-weight: 300!important
}

.menu .NSRElogo,.menu .NSRElogo:hover {
	color: #005b99
}

@media print {
	.NSRE-print {
		background: #fff!important
	}
}

.NSRE-text-link,.NSRE-text-link:hover {
	font-weight: 500;
	color: #00c0e1;
	text-decoration: none;
	cursor: pointer;
}


.NSRElogo {
	font-size: 43px
}


.button {
	display: inline-block;
	padding: 6px 16px!important;
	margin-bottom: 0;
	font-size: 14px!important;
	text-align: center;
	text-shadow: none!important;
	font-weight: 400!important;
	outline: none;
	vertical-align: middle;
	cursor: pointer;
	box-sizing: content-box;
	-moz-box-sizing: content-box
}

.button.btn-lg {
	padding: 11px 19px
}



input.invalid, select.invalid, .radioWrapper.invalid, textarea.invalid, span.checkmark.invalid{
 	border: 2px solid #e85543;
}


input[type=radio].invalid:before{
  content: " ";
  display: inline-block;
  position: relative;
  top: -1px;
  left: -19px;
  margin: 0px;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #e85543;
  background-color: transparent;
}

input[type=checkbox].invalid:before{
  content: " ";
  display: inline-block;
  position: relative;
  top: -1px;
  left: -18px;
  margin: 0px;
  width: 18px;
  height: 18px;
  border-radius: 1px;
  border: 2px solid #e85543;
  background-color: transparent;
}

.select-wrap.invalid{

  border: 2px solid #e85543;

}


.row.justify-content-center {

  display: flex;
  align-items: center;
  justify-content:center!important;

}

.justify-content-center{

  justify-content:center!important;
}


.panel-info .panel-title {

  color: #666666 !important;
}


.panel-info .panel-body {

  	background-color: #fafafa !important;

}


.widget_archive ul,.widget_categories ul,.widget_meta ul,.widget_nav_menu ul,.widget_pages ul,.widget_recent_comments ul,.widget_recent_entries ul,.widget_rss ul,.widget_twitter ul {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	margin-bottom: 0
}


.widget_archive li,.widget_categories li,.widget_meta li,.widget_nav_menu li,.widget_pages li,.widget_recent_comments li,.widget_recent_entries li,.widget_rss li,.widget_twitter li {
	clear: both;
	margin-bottom: 8px;
	position: relative;
	border-bottom: 1px solid;
	padding-bottom: 8px
}

.widget_recent_entries li {
	padding-left: 48px;
	min-height: 45px;
	border-color: rgba(0,0,0,.1)
}

.widget_recent_entries li:last-child {

	border-bottom: 0px;
}

.widget_recent_entries a {
	font-weight: 700
}


.nigo-p {

	background-color: #ffd6d6;
	font-weight: bold;
	color: #d85e4b;
}

.igo-p {
	background-color: #83e2a3;
	font-weight: bold;
	color: #ffffff;
}	

.warining-p > a { color: white; }

.col-grey {background-color:#cccccc}

.panel-body .col-md-4 .col-md-12 {background-color:#ffffff}
.panel-body .col-md-6 .col-md-12 {background-color:#ffffff}
.panel-body .col-md-12 .col-md-12 {background-color:#ffffff}

a.casenum {

	text-decoration: underline;

}

.menu-p {

	font-size: 20px;
	height: 45px;
	background-color:#efefef;

}

.selected-p {

	background-color:#fafafa;
	border-bottom: 2px solid #005B99;
}


.sideProfile {
	margin-top: 60px;
	border:1px solid #eeeeee;
}


ul.pbodylist {

	margin-top: 10px;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0px;
	list-style: none;
}

ul.pbodylist li {

	line-height: 30px;
	height: 30px;
	margin-top: 2px;
	margin-bottom: 2px;
	padding-left: 10px;
}


.stmtList :hover{

  cursor: pointer;
  background-color: #efefef;
}




	.table-hover>tbody>tr:hover, .table>tbody>tr.active>td, .table>tbody>tr.active>th, .table>tbody>tr>td.active, .table>tbody>tr>th.active, .table>tfoot>tr.active>td, .table>tfoot>tr.active>th, .table>tfoot>tr>td.active, .table>tfoot>tr>th.active, .table>thead>tr.active>td, .table>thead>tr.active>th, .table>thead>tr>td.active, .table>thead>tr>th.active {

    background-color: #f5f5f5;
    cursor: pointer;
}


 dl {

    margin: 0px;
    padding:0px;

 }


 .dl-horizontal dt {
    width: 200px;
    white-space: normal!important;
    text-align: left;
 }

 .dl-horizontal dd {
    margin-left: 200px;
 }


#info{

	-webkit-font-smoothing: antialiased
}

.userAttribute {

	background-color: #ffffff;
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #6891c0; 
	margin-bottom: 10px;
}

.userAttributeChange {

	background-color: #ededed;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 10px;
}

.pipeSep{

	font-size: 26px;
	line-height: 9px;
	color: #eaeaea;
}

.editSep{

	padding-right: 25px;
	border-right: 1px solid #cccccc;
}

button.buttonLink, button.buttonLink:active {

		background-color: #ffffff;
		color: #00c0e1;
		text-decoration: underline;
}

button.cancelChange2 {

		background-color: #ededed;
		color: #ff0000;
		font-size: 24px;
		font-weight: normal;
}

button.cancelChange2:active {

		background-color: #ff0000;
		color: #ededed;
		font-size: 24px;
		font-weight: normal;
}

button.cancelChange {

		background-color: #FF0000;
		color: #FFFFFF;
		font-weight: normal;
}

label {

	font-weight: normal;
}

.alert {
	padding: 10px;
}

.groupLine.divider-border-inner {
	background-color: lightsteelblue;
}

.alert-success {

	display: none;
}

div#action div.dropdown {

	margin-top:24px;

}


	#sessionTimerBlock {
	    position:fixed; z-index:10;right:0px;bottom:0px;padding:5px 10px 5px 10px;font-size:small;background:#000;color:#e0e0e0;display: none;
	  }

	#sessionTimer {
		font-weight:bold;font-size:larger;color:#fff; padding-left: 5px; padding-right: 5px;
	}


      .modal-dialog {
        position: relative;
        margin: 200px auto;
      }




      .switch {
        position: relative;
        display: inline-block;
        width: 100px;
        height: 34px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #008d7d;
        -webkit-transition: .4s;
        transition: .4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

      input:checked + .slider {
        background-color: #2196F3;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(64px);
        -ms-transform: translateX(64px);
        transform: translateX(64px);
      }

      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }



