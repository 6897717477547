@charset "UTF-8";

#content {
	display: flex;
	flex-direction: column;
}

article#app {
	min-height: 70vh;
}

.panel,.panel-group .panel,.well {
	border-radius: 0;
	border: 0
}

.well {
	position: relative;
	box-shadow: none
}

.panel-group {
	border: 0;
	margin-bottom: 12px
}

.panel-group .panel {
	box-shadow: none
}

.panel-group .panel p:last-child,.tab-content p:last-child {
	margin-bottom: 0
}

.panel-group .panel+.panel {
	margin-top: 1px
}

.panel-heading {
	position: relative;
	border: none;
	border-radius: 0
}

.panel-heading .accordion-toggle:after {
	position: absolute;
	display: block;
	font-family: FontAwesome;
	content: "\f106";
	font-size: 14px;
	right: 0;
	top: 0;
	width: 40px;
	height: 44px;
	line-height: 44px;
	text-align: center;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.panel-heading .accordion-toggle.collapsed:after {
	content: "\f107"
}

.panel-title {
	margin-bottom: 0!important
}

.pagination {
	margin: 24px 0
}

.pagination>li span,.pagination>li>a {
	padding: 6px 14px;
	border: 0;
	font-weight: 400
}


.panel-default .panel-heading {
	background: #4d4a51!important
}

.panel-danger .panel-heading a,.panel-danger .panel-heading a:hover,.panel-default .panel-heading a,.panel-default .panel-heading a:hover,.panel-default .panel-title,.panel-warning .panel-heading a,.panel-warning .panel-heading a:hover {
	color: #fff!important
}

.panel-warning .panel-heading {
	background: #cd6727!important
}

.panel-warning .panel-title {
	color: #fff!important
}

.panel-danger .panel-heading {
	background: #e85543!important
}

.panel-danger .panel-title,.panel-success .panel-heading a,.panel-success .panel-heading a:hover {
	color: #fff!important
}

.panel-success .panel-heading {
	background: #008d7d!important
}

.panel-info .panel-heading a,.panel-info .panel-heading a:hover,.panel-success .panel-title {
	color: #fff!important
}

.panel-info .panel-heading {
	background: #e1e8ef !important
}

.panel-info .panel-title {
	color: #fff!important
}


