@charset "UTF-8";


.pace,.pace-on .pace-overlay {
	position: fixed;
	z-index: 2000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity .5s 0s,height 0s .5s
}

.pace {
	z-index: 2001;
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.pace-done .pace-overlay,.pace-inactive {
	height: 0;
	opacity: 0
}

.pace-dot .pace .pace-progress {
	transform: translate3d(0,0,0)!important;
	position: fixed;
	width: 100%;
	height: 100%
}

.pace-dot .pace-progress-inner {
	width: 60px;
	height: 60px;
	position: absolute;
	left: 50%;
	top: 50%;
	border-radius: 100%;
	margin-left: -30px;
	margin-right: -30px;
	animation: scaleout 1s infinite ease-in-out
}

@keyframes scaleout {
	0% {
		transform: scale(0);
		-webkit-transform: scale(0)
	}

	to {
		transform: scale(1);
		-webkit-transform: scale(1);
		opacity: 0
	}
}

.pace-minimal.pace-on {
	background-size: auto
}

.pace-minimal.pace-on #content,.pace-minimal.pace-on .menu,.pace-minimal.pace-on .top-bar {
	visibility: visible
}

.pace-minimal .pace .pace-progress {
	position: absolute;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 4px;
	transition: width 1s
}

.pace-counter .pace .pace-progress {
	z-index: 2000;
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	height: 100px;
	width: 200px!important;
	margin-top: -50px;
	margin-left: -100px;
	line-height: 100px;
	font-size: 60px;
	color: #fff;
	font-family: "Helvetica Neue",sans-serif;
	font-weight: 100;
	text-align: center;
	transform: translate3d(0,0,0)!important
}

.pace-counter .pace .pace-progress:after {
	content: attr(data-progress-text);
	display: block
}

.pace-logo .pace,.pace-logo-3d .pace {
	width: 100px;
	height: 100px;
	top: 50%;
	left: 50%;
	background-size: 80%;
	background-repeat: no-repeat;
	background-position: center
}

.pace-logo .pace {
	transform: translate3d(-50%,-50%,0);
	overflow: hidden
}

.pace-logo .pace:before {
	content: ""
}

.pace-logo .pace .pace-progress,.pace-logo .pace:before {
	position: absolute;
	z-index: 2000;
	bottom: 0;
	left: 10%;
	width: 80%;
	height: 4px
}

.pace-logo-3d .pace {
	margin-left: -50px;
	margin-top: -50px;
	animation: logo-rotate 2.2s infinite ease-in-out
}


