@font-face {
	font-family:"nsre";src:url(../fonts/nsre.eot);src:url(../fonts/nsre.eot?#iefix) format("embedded-opentype"),url(../fonts/nsre.woff) format("woff"),url(../fonts/nsre.ttf) format("truetype"),url(../fonts/nsre.svg#nsre) format("svg");font-weight:400;font-style:normal
}

body {
	font-size: 14px
}

body,body .btn,body button,body input,body textarea,h1,h2,h3,h4,h5,h6 {
	font-family: "Open Sans",sans-serif
}


h1.headings-caps,h2.headings-caps,h3.headings-caps,h4.headings-caps,h5.headings-caps,h6,h6.headings-caps {
	text-transform: uppercase
}

h1 {
	font-size: 36px
}

h1 small {
	font-size: .6em
}

h2 {
	font-size: 24px
}

h2 small,h3 small,h4 small {
	font-size: .75em
}

h3 {
	font-size: 18px
}

h4 {
	font-size: 16px
}

h5 {
	font-size: 13px
}

h6 {
	font-size: 11px;
	margin: 0
}

.header {
	margin-top: 0;
	margin-bottom: 0
}

a {
	transition: color .2s
}

a,a:hover {
	color: #00c0e1;
	text-decoration: none;
}

a:active,a:focus {
	outline: none;
	text-decoration: none
}

.small,small {
	font-size: .85em;
	line-height: inherit;
	color: #959494;
}

figure {
	margin: 0 0 24px
}

abbr,acronym,code,dir,kbd,listing,plaintext,pre,q,samp,tt,var,xmp {
	font-size: 14px;
	word-break: normal;
	-webkit-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
	border: 0
}

pre {
	text-shadow: none;
	border-radius: 0;
	line-height: 1.2
}


.hairline {
	font-weight: 100!important
}

.hairline strong,body {
	font-weight: 300
}

.light {
	font-weight: 300!important
}

.regular {
	font-weight: 400!important
}

.bold {
	font-weight: 700!important
}

.black {
	font-weight: 900!important
}


h1,h2,h3,h4,h5,h6 {
	font-weight: 600;
	text-transform: uppercase;
	line-height: 1.5em;
}

.description p,.lead {
	font-size: 21px;
	font-weight: 300;
	line-height: 1.75em
}

.lead strong {
	font-weight: 700
}

.text-left {
	text-align: left!important
}

.text-right {
	text-align: right!important
}

.text-center {
	text-align: center!important
}

.text-justify {
	text-align: justify!important
}

.big {
	font-size: 36px;
	line-height: 48px
}

.text-light .breadcrumb-blog .active,.text-light .breadcrumb-blog a,.text-light .breadcrumb-blog>li+li:before,.text-light .scroll-to i,.text-light .small,.text-light small,.text-light.scroll-to i {
	color: #fff
}

.text-light,.text-light .btn-link i,.text-light a,.text-light blockquote,.text-light blockquote cite,.text-light blockquote footer,.text-light blockquote small,.text-light h1,.text-light h2,.text-light h3,.text-light h4,.text-light h5,.text-light h6,.text-light i,.text-light.btn-link i,a.text-light,blockquote.text-light,blockquote.text-light cite,blockquote.text-light footer,blockquote.text-light small,h1.text-light,h2.text-light,h3.text-light,h4.text-light,h5.text-light,h6.text-light {
	color: #fff
}


