.wufoo label.desc, .wufoo legend.desc {
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  color: #444444;
}

.wufoo input.text, .wufoo textarea.textarea, .wufoo select.select, .wufoo label.choice {
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  color: #333333;
}

.wufoo input.btTxt {
  font-size: 100%;
  padding: 5px 15px 5px 15px;
  border-radius: 4px;
}

body {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
body input, body textarea, body .btn, body button {
  font-family: "Open Sans", sans-serif;
}

blockquote p {
  font-size: 24px;
  font-weight: 400;
}

.hairline {
  font-weight: 100 !important;
}
.hairline strong {
  font-weight: 300;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.bold {
  font-weight: 700 !important;
}

.black {
  font-weight: 900 !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-transform: none;
}

.lead {
  font-size: 21px;
  font-weight: 400;
  line-height: 1.75em;
}
.lead strong {
  font-weight: 700;
}

#masthead .menu, #masthead.menu, #navbar-slide.menu {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
#masthead .menu .dropdown-menu, #masthead.menu .dropdown-menu, #navbar-slide.menu .dropdown-menu {
  font-size: 14px;
}

.menu .navbar-brand {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 26px;
}

@media print {
  .NSRE-print {
    background: #ffffff !important;
  }
}

.NSRE-text-link {
  font-weight: 500;
  color: #3F5673;
  text-decoration: underline;
}
.NSRE-text-link:hover {
  text-decoration: underline;
  font-weight: 500;
  color: #00c0e1;
}

.ul-bullet-solid {
  list-style-type: disc;
}

.nsre-note {
  list-style-type: none;
  font-weight: bold;
  font-style: italic;
}

@media (max-width: 992px) {
  .NSRE-mobile-right {
    background-position: 75% 0%;
  }

  .NSRE-mobile-center {
    background-position: 50% 0%;
  }

  .NSRE-mobile-left-center {
    background-position: 40% 0%;
  }

  .NSRE-mobile-off-left {
    background-position: 15% 0%;
  }
}
.NSRElogo {
  font-size: 43px;
}

.post-head h1 {
  font-size: 36px;
  font-weight: 500;
}
.post-head h2 {
  font-size: 28.8px;
  font-weight: 500;
}
.post-head .lead {
  color: #959494;
}

.post-details {
  color: #959494;
}
.post-details a, .post-details i {
  color: #959494;
}
.post-details a:hover, .post-details a:focus {
  color: #00c0e1;
}
.post-details span {
  border-color: rgba(0, 0, 0, 0.1);
}

.post-title span {
  color: #959494;
}

.post-body {
  font-size: 14px;
  font-weight: 300;
}

.post-extras {
  color: #959494;
}
.post-extras a {
  color: #959494;
}
.post-extras a:hover, .post-extras a:focus {
  color: #00c0e1;
}

.post-tags a, .post-share ul.social-icons li a {
  border-radius: 0px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  color: #4c4c4c;
}
.post-tags a:hover, .post-share ul.social-icons li a:hover {
  box-shadow: 0px 0px 0px 1px #00c0e1;
  color: #00c0e1;
}
.post-tags a:hover i, .post-share ul.social-icons li a:hover i {
  color: #00c0e1 !important;
}

.btn, button, input[type="submit"], .button {
  border-radius: 0px;
  font-size: 16px;
}
.btn.btn-lg, button.btn-lg, input[type="submit"].btn-lg, .button.btn-lg {
  border-radius: 0px;
  font-size: 20px;
}
.btn.btn-sm, button.btn-sm, input[type="submit"].btn-sm, .button.btn-sm {
  border-radius: 0px;
  font-size: 14px;
}

.portfolio-title {
  border-radius: 0px;
}

.pager li > a, .pager li > span {
  border-radius: 0px;
}

.form-control,
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.select-wrap {
  font-size: 16px;
  border-radius: 0px;
}

.widget_tag_cloud ul li a {
  border-radius: 0px;
}

.tag-list-inline li span {
  border-radius: 0px;
}

.tag-list-lg li span {
  border-radius: 0px;
}

.tag-list-sm li span {
  border-radius: 0px;
}

.skills-list li:before {
  color: #00c0e1;
}

.map-marker-label {
  font-size: 16px;
  font-weight: 400;
}

.navbar-brand {
  padding: 0;
  line-height: 100px;
  height: 100px;
  text-transform: uppercase;
}

.logo-navbar .nav > li > a {
  padding-top: 38px;
  padding-bottom: 38px;
  line-height: 24px;
}
.logo-navbar .nav > li > a:before {
  margin-bottom: -21px;
}
.logo-navbar .nav > li.active > a:before, .logo-navbar .nav > li > a:hover:before, .logo-navbar .nav > li.current-page-parent > a:before, .logo-navbar .nav > li.current-menu-parent > a:before, .logo-navbar .nav > li.current-page-ancestor > a:before, .logo-navbar .nav > li.current-menu-ancestor > a:before {
  opacity: 1;
  margin-bottom: -19px;
}

#masthead.navbar {
  min-height: 100px;
}

.navbar-toggle {
  margin-top: 42px;
  margin-bottom: 42px;
}

#masthead .dropdown-menu {
  min-width: 220px;
}

@media (min-width: 992px) {
  #masthead.navbar-scrolled {
    min-height: 90px;
  }
  #masthead.navbar-scrolled .navbar-brand {
    line-height: 90px;
    height: 90px;
    color: #4c4c4c;
  }

  #masthead.navbar-scrolled .logo-navbar .navbar-nav > li > a {
    padding-top: 33px;
    padding-bottom: 33px;
    color: #4c4c4c;
  }

  #masthead.navbar-scrolled .logo-navbar .navbar-text, #masthead.navbar-scrolled .logo-navbar .sidebar-widget {
    padding-top: 33px;
    padding-bottom: 33px;
  }

  .navbar-scrolled .navbar-toggle {
    margin-top: 37px;
    margin-bottom: 37px;
  }

  #masthead .logo-navbar .search-close {
    top: 0;
    height: 100px;
    width: 100px;
  }
  #masthead .logo-navbar .form-control {
    height: 99px;
  }

  #masthead.navbar-scrolled .logo-navbar .menu-sidebar .search-close {
    top: 0;
    height: 90px;
    width: 90px;
  }
  #masthead.navbar-scrolled .logo-navbar .form-control {
    height: 90px;
  }

  .logo-navbar .navbar-text, .logo-navbar .sidebar-widget {
    line-height: 24px;
    padding-top: 38px;
    padding-bottom: 38px;
  }

  .transparent-header #content {
    margin-top: -100px;
    display: inline-block;
    vertical-align: top;
  }
  .transparent-header #content.layout-boxed {
    display: inline-block;
  }

  .transparent-header.layout-boxed #content {
    display: block;
  }

  .transparent-header.transparent-menu-below #content {
    margin-top: -150px;
  }

  .transparent-topbar #content {
    margin-top: -136px;
  }

  .transparent-topbar.transparent-menu-below #content {
    margin-top: -186px;
  }
}
#navbar-slide {
  width: 270px;
}
#navbar-slide .navbar-nav {
  max-height: 600px;
}
#navbar-slide .navbar-brand {
  height: 100px;
}

.slide-on #masthead, .slide-on #content, .slide-on #navbar-slide, .slide-on .top-bar {
  -ms-transform: translateX(-270px);
  transform: translateX(-270px);
}

.rtl.slide-on #masthead, .rtl.slide-on #content, .rtl.slide-on #navbar-slide, .rtl.slide-on .top-bar {
  -ms-transform: translateX(270px);
  transform: translateX(270px);
}

@media (min-width: 992px) {
  .side-menu #content {
    margin-top: -100px;
  }

  .side-menu-left #content {
    padding-left: 270px;
  }
  .side-menu-left.rtl #content {
    padding-left: 0;
    padding-right: 270px;
  }

  .side-menu-right #content {
    padding-right: 270px;
  }
  .side-menu-right.rtl #content {
    padding-right: 0;
    padding-left: 270px;
  }
}
.btn i, button i, .button i {
  color: inherit !important;
}

.btn-default {
  background: #4D4A51 !important;
  color: #ffffff !important;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default:active:focus, .btn-default:active:hover {
  background: rgba(77, 74, 81, 0.9) !important;
}

.btn-warning {
  background: #cd6727 !important;
  color: #ffffff !important;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning:active:focus, .btn-warning:active:hover {
  background: rgba(205, 103, 39, 0.9) !important;
}

.btn-danger {
  background: #e85543 !important;
  color: #ffffff !important;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger:active:focus, .btn-danger:active:hover {
  background: rgba(232, 85, 67, 0.9) !important;
}

.btn-success {
  background: #008D7D !important;
  color: #ffffff !important;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success:active:focus, .btn-success:active:hover {
  background: rgba(0, 141, 125, 0.9) !important;
}

.btn-info {
  background: #78A2BB !important;
  color: #ffffff !important;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info:active:focus, .btn-info:active:hover {
  background: rgba(120, 162, 187, 0.9) !important;
}

.panel-default .panel-heading {
  background: #4D4A51 !important;
}
.panel-default .panel-heading a, .panel-default .panel-heading a:hover {
  color: #ffffff !important;
}
.panel-default .panel-title {
  color: #ffffff !important;
}

.panel-warning .panel-heading {
  background: #cd6727 !important;
}
.panel-warning .panel-heading a, .panel-warning .panel-heading a:hover {
  color: #ffffff !important;
}
.panel-warning .panel-title {
  color: #ffffff !important;
}

.panel-danger .panel-heading {
  background: #e85543 !important;
}
.panel-danger .panel-heading a, .panel-danger .panel-heading a:hover {
  color: #ffffff !important;
}
.panel-danger .panel-title {
  color: #ffffff !important;
}

.panel-success .panel-heading {
  background: #008D7D !important;
}
.panel-success .panel-heading a, .panel-success .panel-heading a:hover {
  color: #ffffff !important;
}
.panel-success .panel-title {
  color: #ffffff !important;
}

.panel-info .panel-heading {
  background: #78A2BB !important;
}
.panel-info .panel-heading a, .panel-info .panel-heading a:hover {
  color: #ffffff !important;
}
.panel-info .panel-title {
  color: #ffffff !important;
}

.alert-warning {
  background: #cd6727;
  color: #ffffff !important;
}
.alert-warning a {
  color: #ffffff !important;
  text-decoration: underline;
}
.alert-warning .btn, .alert-warning .button {
  background: #cd6727 !important;
  color: #ffffff !important;
  text-decoration: none;
  border: 1px solid #ffffff;
}
.alert-warning .close {
  color: #ffffff !important;
}

.alert-danger {
  background: #e85543;
  color: #ffffff !important;
}
.alert-danger a {
  color: #ffffff !important;
  text-decoration: underline;
}
.alert-danger .btn, .alert-danger .button {
  background: #e85543 !important;
  color: #ffffff !important;
  text-decoration: none;
  border: 1px solid #ffffff;
}
.alert-danger .close {
  color: #ffffff !important;
}

.NSRE-alert-news {
  background: #e85543;
  color: #ffffff !important;
  padding-top: 12px;
  padding-bottom: 12px;
}
.NSRE-alert-news a {
  color: #ffffff !important;
  text-decoration: underline;
}
.NSRE-alert-news .btn, .NSRE-alert-news .button {
  background: #e85543 !important;
  color: #ffffff !important;
  text-decoration: none;
  border: 1px solid #ffffff;
}
.NSRE-alert-news .close {
  color: #ffffff !important;
}

.alert-success {
  background: #008D7D;
  color: #ffffff !important;
}
.alert-success a {
  color: #ffffff !important;
  text-decoration: underline;
}
.alert-success .btn, .alert-success .button {
  background: #008D7D !important;
  color: #ffffff !important;
  text-decoration: none;
  border: 1px solid #ffffff;
}
.alert-success .close {
  color: #ffffff !important;
}

.alert-info {
  background: #78A2BB;
  color: #ffffff !important;
}
.alert-info a {
  color: #ffffff !important;
  text-decoration: underline;
}
.alert-info .btn, .alert-info .button {
  background: #78A2BB !important;
  color: #ffffff !important;
  text-decoration: none;
  border: 1px solid #ffffff;
}
.alert-info .close {
  color: #ffffff !important;
}

.progress-bar-default {
  box-shadow: none;
  background: #4D4A51;
}
.progress-bar-default span {
  color: #ffffff;
}

.progress-bar-default.progress-striped .progress-bar, .progress-striped .progress-bar-default {
  background-color: #4D4A51;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-warning {
  background: #cd6727;
  box-shadow: none;
}
.progress-bar-warning span {
  color: #ffffff;
}

.progress-bar-warning.progress-striped .progress-bar, .progress-striped .progress-bar-warning {
  background-color: #cd6727;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-danger {
  box-shadow: none;
  background: #e85543;
}
.progress-bar-danger span {
  color: #ffffff;
}

.progress-bar-danger.progress-striped .progress-bar, .progress-striped .progress-bar-danger {
  background-color: #e85543;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-success {
  box-shadow: none;
  background: #008D7D;
}
.progress-bar-success span {
  color: #ffffff;
}

.progress-bar-success.progress-striped .progress-bar, .progress-striped .progress-bar-success {
  background-color: #008D7D;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-info {
  box-shadow: none;
  background: #78A2BB;
}
.progress-bar-info span {
  color: #ffffff;
}

.progress-bar-info.progress-striped .progress-bar, .progress-striped .progress-bar-info {
  background-color: #78A2BB;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.figure-overlay {
  background-color: rgba(55, 147, 155, 0.9);
  color: #ffffff;
}
.figure-overlay .figure-caption {
  color: #ffffff;
}
.figure-overlay .figure-caption h1, .figure-overlay .figure-caption h2, .figure-overlay .figure-caption h3, .figure-overlay .figure-caption h4, .figure-overlay .figure-caption h5, .figure-overlay .figure-caption i, .figure-overlay .figure-caption a {
  color: inherit !important;
}
.figure-overlay .figure-caption small {
  color: rgba(255, 255, 255, 0.8) !important;
}
.figure-overlay .figure-caption .btn {
  background: #ffffff !important;
  color: rgba(55, 147, 155, 0.9) !important;
}
.figure-overlay .figure-caption .btn:hover {
  background: rgba(255, 255, 255, 0.8) !important;
}
.figure-overlay .figure-caption i {
  font-size: 21px;
}
.fade-none.figcaption-top .figure-overlay {
  background: linear-gradient(to bottom, rgba(55, 147, 155, 0.9) 60%, rgba(55, 147, 155, 0) 100%);
}
.fade-none.figcaption-bottom .figure-overlay {
  background: linear-gradient(to bottom, rgba(55, 147, 155, 0) 60%, #37939b 100%);
}

.mfp-bg {
  background: #ffffff;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  background-color: #000000 !important;
  color: #ffffff;
}

.mfp-counter, .mfp-preloader {
  color: #000000;
}

button.mfp-arrow {
  background: #000000 !important;
}

.mfp-title {
  color: black;
}

.mfp-arrow:before {
  color: #ffffff;
}

.go-top {
  background: #f7c026 !important;
}
.go-top i {
  color: #333;
}

.carousel-control {
  color: #ffffff !important;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
}

.carousel-caption, .carousel-caption h3 {
  color: #ffffff !important;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
}

.carousel-indicators li {
  background: rgba(0, 0, 0, 0.4);
  border-color: transparent;
}

.carousel-indicators li.active {
  background: #ffffff;
  border-color: #ffffff;
}

.flex-direction-nav a {
  color: #ffffff !important;
  text-shadow: none;
  background: rgba(0, 0, 0, 0.4);
}

.flex-control-nav a {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.4);
}

.flex-control-nav a.flex-active {
  background: #ffffff;
}

.flexslider figcaption {
  background: rgba(0, 0, 0, 0.4);
}
.flexslider figcaption h3, .flexslider figcaption p {
  color: #ffffff;
}

.ls-fullwidth .ls-nav-prev:after, .ls-fullwidth .ls-nav-next:after {
  color: #ffffff !important;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
}

.gform_wrapper .gfield_required.gfield_error, .gform_wrapper .gfield_error .gfield_label, .gform_wrapper .gfield_contains_required.gfield_error {
  color: #e85543;
}

.gform_wrapper .gfield_error input, .gform_wrapper .gfield_error select, .gform_wrapper .gfield_error textarea {
  border-color: #e85543;
}

.validation_error {
  background: #e85543;
  color: #ffffff;
  border-radius: 0px;
}

.validation_error hr {
  border-top-color: #e85543;
}

.validation_error .alert-link {
  color: #e6e6e6;
}

.select2-container {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 0px;
}

.select2-container .select2-choice {
  color: #3c3c3c;
}

.select2-container .select2-choice .select2-arrow b:after {
  color: #3c3c3c;
}

.select2-drop-active {
  background: rgba(0, 0, 0, 0.8);
  color: #3c3c3c;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.select2-results .select2-highlighted {
  background-color: #00c0e1;
  color: #3c3c3c;
}

.select2-results {
  background: rgba(0, 0, 0, 0.02);
}

.select2-search, .select2-results {
  background: rgba(0, 0, 0, 0.02);
}

.panel-bbpress .bbp-template-notice {
  color: #e85543;
}

[role="alert"] {
  color: #e85543;
}
[role="alert"].wpcf7-mail-sent-ok {
  color: #008D7D;
}

.wpcf7-not-valid {
  border-color: #e85543 !important;
}

.table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th, .table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th, .table > thead > tr > td.warning, .table > thead > tr > th.warning {
  color: #ffffff;
  background-color: #cd6727;
}

.table > tbody > tr.error > td, .table > tbody > tr.error > th, .table > tbody > tr > td.error, .table > tbody > tr > th.error, .table > tfoot > tr.error > td, .table > tfoot > tr.error > th, .table > tfoot > tr > td.error, .table > tfoot > tr > th.error, .table > thead > tr.error > td, .table > thead > tr.error > th, .table > thead > tr > td.error, .table > thead > tr > th.error {
  color: #ffffff;
  background-color: #e85543;
}

.table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tbody > tr > td.info, .table > tbody > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th, .table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th, .table > thead > tr > td.info, .table > thead > tr > th.info {
  color: #ffffff;
  background-color: #78A2BB;
}

.table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > thead > tr > td.success, .table > thead > tr > th.success {
  color: #ffffff;
  background-color: #008D7D;
}

.has-warning .form-control {
  border-color: #cd6727;
}

.has-error .form-control {
  border-color: #e85543;
}

.has-success .form-control {
  border-color: #ffffff;
}

.has-info .form-control {
  border-color: #78A2BB;
}

.post-grid {
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.1);
}
.post-grid .post-grid-content-title a {
  color: #1c1c1c;
}
.post-grid:hover .post-grid-content-title a {
  color: #00c0e1;
}
.post-grid blockquote p {
  font-size: 19.2px;
}
.post-grid .post-grid-content-footer, .post-grid blockquote footer {
  border-top-color: rgba(0, 0, 0, 0.1);
}

.post-grid-overlay {
  background-color: rgba(55, 147, 155, 0.9);
}
.post-grid-overlay .post-grid-content-title, .post-grid-overlay .post-grid-content-footer, .post-grid-overlay blockquote p, .post-grid-overlay blockquote footer {
  color: #ffffff;
}
.post-grid-overlay .post-grid-content {
  background: rgba(55, 147, 155, 0.9);
}
.post-grid-overlay blockquote:before {
  color: rgba(255, 255, 255, 0.7);
}

.figure-caption-category a {
  background: rgba(0, 192, 225, 0.8);
  color: #ffffff;
  transition: all .2s;
}
.figure-caption-category a:hover {
  background: rgba(0, 192, 225, 0.9);
  color: #ffffff;
}

#content {
  background: #ffffff;
  color: #4c4c4c;
}

h1, h2, h3, h4, h5, h6 {
  color: #1c1c1c;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  color: #959494;
}

a {
  color: #00c0e1;
}
a:hover, a:focus {
  color: #08b7d4;
}

small, .small {
  color: #959494;
}
small a, .small a {
  color: #959494;
}
small a:hover, small a:focus, .small a:hover, .small a:focus {
  color: #00c0e1;
}
small i, .small i {
  color: #959494;
}

i {
  color: #6c6c6c;
}

blockquote p {
  font-family: "Gentium Basic";
  font-style: italic;
}
blockquote small, blockquote footer {
  font-family: "Open Sans";
  color: #959494;
}
blockquote cite {
  color: #4c4c4c;
}
blockquote:before {
  color: rgba(76, 76, 76, 0.2);
}
blockquote.blockquote-simple img {
  border-color: rgba(0, 0, 0, 0.4);
}

::selection {
  background: #2e3333;
  color: #ffffff;
}

::-moz-selection {
  background: #2e3333;
  color: #ffffff;
}

hr {
  border-top-color: rgba(0, 0, 0, 0.1);
}

pre {
  background: #2e3333;
  color: #ffffff;
}

.btn-primary, button, input[type="submit"] {
  background-color: #00c0e1;
  color: #ffffff;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:active:focus, .btn-primary:active:hover, button:hover, button:active, button:focus, button:active:focus, button:active:hover, input[type="submit"]:hover, input[type="submit"]:active, input[type="submit"]:focus, input[type="submit"]:active:focus, input[type="submit"]:active:hover {
  background-color: #009db8;
  color: #ffffff;
}

.btn-link {
  background: none;
  color: #00c0e1;
  text-decoration: none;
  box-shadow: 0px 0px 0px 1px #00c0e1 inset;
}
.btn-link:hover, .btn-link:active, .btn-link:focus, .btn-link:active:focus, .btn-link:active:hover {
  text-decoration: none;
  background-color: #00c0e1;
  color: #ffffff;
  box-shadow: 0px 0px 0px 1px #00c0e1 inset;
}

.open .dropdown-toggle.btn-primary {
  background-color: #00c0e1;
  color: #ffffff;
}

.btn-group .dropdown-menu {
  background-color: #00c0e1;
}
.btn-group .dropdown-menu > li > a {
  color: #ffffff;
  box-shadow: 0px -1px 0px #00aac8;
}
.btn-group .dropdown-menu > li:first-child > a {
  box-shadow: none;
}
.btn-group .dropdown-menu > li > a:hover {
  background: #06daff;
}

.label-primary {
  background: #00c0e1;
  color: #ffffff;
}
.label-primary a {
  color: #ffffff;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.select-wrap,
.form-control {
  background-color: rgba(0, 0, 0, 0.02);
  color: #3c3c3c;
  border-color: rgba(0, 0, 0, 0.1);
}
select:-moz-placeholder,
textarea:-moz-placeholder,
input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="datetime"]:-moz-placeholder,
input[type="datetime-local"]:-moz-placeholder,
input[type="date"]:-moz-placeholder,
input[type="month"]:-moz-placeholder,
input[type="time"]:-moz-placeholder,
input[type="week"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="url"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="color"]:-moz-placeholder,
.uneditable-input:-moz-placeholder,
.select-wrap:-moz-placeholder,
.form-control:-moz-placeholder {
  color: #9c9c9c;
}
select::-moz-placeholder,
textarea::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="datetime"]::-moz-placeholder,
input[type="datetime-local"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="month"]::-moz-placeholder,
input[type="time"]::-moz-placeholder,
input[type="week"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="color"]::-moz-placeholder,
.uneditable-input::-moz-placeholder,
.select-wrap::-moz-placeholder,
.form-control::-moz-placeholder {
  color: #9c9c9c;
}
select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="datetime-local"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
input[type="week"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="color"]:-ms-input-placeholder,
.uneditable-input:-ms-input-placeholder,
.select-wrap:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #9c9c9c;
}
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="datetime-local"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
input[type="week"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="color"]::-webkit-input-placeholder,
.uneditable-input::-webkit-input-placeholder,
.select-wrap::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #9c9c9c;
}

.form-control:focus {
  border-color: #00c0e1;
}

input[type="checkbox"], input[type="radio"] {
  box-shadow: none;
}

.form-icon-group i {
  color: #00c0e1;
}

input[type="button"] {
  background: #00c0e1;
  color: #ffffff;
}

.select-wrap {
  color: #3c3c3c;
  background: rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1) inset;
}

.select-wrap:after {
  color: #3c3c3c;
}

.table > thead {
  border-color: rgba(0, 0, 0, 0.1);
}

.table > thead > tr > th {
  background-color: rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  color: #4c4c4c;
  font-family: "Open Sans";
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  border-color: rgba(0, 0, 0, 0.1);
}

.table tfoot th {
  background-color: rgba(0, 0, 0, 0.1);
}

.table-hover tbody tr:hover th {
  background: rgba(0, 0, 0, 0.1);
}

.table-striped > tbody > tr:nth-child(odd) > td, .table-striped > tbody > tr:nth-child(odd) > th, .table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {
  background: rgba(0, 0, 0, 0.1);
}

#masthead {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.06), 0px -1px 0px rgba(0, 0, 0, 0.06);
}

.menu {
  color: #4c4c4c;
  background: #ffffff;
}

.menu .navbar-text {
  color: #4c4c4c;
}

.menu .navbar-brand {
  color: #1c1c1c;
}
.menu .navbar-brand:hover {
  color: #1c1c1c;
}

.menu .NSRElogo {
  color: #005B99;
}
.menu .NSRElogo:hover {
  color: #005B99;
}

.menu a {
  color: #1c1c1c;
}
.menu a:before {
  height: 3px;
}

.menu .nav > li > a {
  color: #1c1c1c;
}
.menu .nav > li > a:hover, .menu .nav > li > a:focus {
  color: #00c0e1;
  background: none;
}
.menu .nav > li > a:hover:before, .menu .nav > li > a:focus:before {
  opacity: 1;
  width: 100%;
  background-color: #00c0e1;
}

.menu a .menu-icon {
  color: inherit;
}

.navbar-toggle {
  background: none !important;
}
.navbar-toggle .icon-bar {
  background-color: #1c1c1c;
}
.navbar-toggle:hover {
  background: none;
}

.menu .nav > .active > a,
.menu .nav > .current-menu-parent > a,
.menu .nav > .current-menu-ancestor > a,
.menu .nav > .current-menu-item > a,
.menu .nav > .current-menu-item > a:focus,
.menu .nav > .current-page-parent > a,
.menu .nav > .active > a:focus,
.menu .nav li.dropdown.open > .dropdown-toggle,
.menu .nav li.dropdown.active > .dropdown-toggle,
.menu .nav li.dropdown.open.active > .dropdown-toggle {
  color: #00c0e1;
}
.menu .nav > .active > a:after,
.menu .nav > .current-menu-parent > a:after,
.menu .nav > .current-menu-ancestor > a:after,
.menu .nav > .current-menu-item > a:after,
.menu .nav > .current-menu-item > a:focus:after,
.menu .nav > .current-page-parent > a:after,
.menu .nav > .active > a:focus:after,
.menu .nav li.dropdown.open > .dropdown-toggle:after,
.menu .nav li.dropdown.active > .dropdown-toggle:after,
.menu .nav li.dropdown.open.active > .dropdown-toggle:after {
  border-radius: 0px;
}
.menu .nav > .active > a:hover,
.menu .nav > .current-menu-parent > a:hover,
.menu .nav > .current-menu-ancestor > a:hover,
.menu .nav > .current-menu-item > a:hover,
.menu .nav > .current-menu-item > a:focus:hover,
.menu .nav > .current-page-parent > a:hover,
.menu .nav > .active > a:focus:hover,
.menu .nav li.dropdown.open > .dropdown-toggle:hover,
.menu .nav li.dropdown.active > .dropdown-toggle:hover,
.menu .nav li.dropdown.open.active > .dropdown-toggle:hover {
  color: #00c0e1;
}

.menu .nav > .disabled > a {
  opacity: 0.8;
}

.menu .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 0px #00c0e1 inset;
}
.menu .dropdown-menu > li > a {
  color: #4c4c4c;
}
.menu .dropdown-menu > li > a .menu-icon {
  color: inherit;
}

.menu .dropdown-menu > li > a:hover, .menu .dropdown-menu > .active > a, .menu .dropdown-menu > .active > a:hover, .menu .dropdown-menu > .active > a:focus {
  color: #00c0e1;
  background: transparent;
}

.menu .nav > li > .dropdown-menu:after, .menu .nav > li > .dropdown-menu:before {
  display: none;
}

.menu .dropdown-submenu > a:after {
  border-left-color: #4c4c4c !important;
}

.menu .dropdown-menu .divider {
  background-color: rgba(0, 0, 0, 0.055);
}

.nav-highlight > a, .nav-highlight-ghost > a {
  border-radius: 0px;
}
.nav-highlight > a:after, .nav-highlight-ghost > a:after {
  border-radius: 0px;
}

.menu .nav-highlight > a {
  color: #ffffff !important;
}
.menu .nav-highlight > a:after {
  background: #1c1c1c;
}
.menu .nav-highlight > a:hover {
  color: #ffffff !important;
}
.menu .nav-highlight > a:hover:after {
  background: #00c0e1;
}
.menu .nav-highlight.active > a {
  color: #ffffff !important;
}
.menu .nav-highlight.active > a:after {
  background: #00c0e1;
}
.menu .nav-highlight.active > a:hover {
  color: #ffffff !important;
}
.menu .nav-highlight.active > a:hover:after {
  background: #00c0e1;
}
.menu .nav-highlight .menu-icon {
  color: #ffffff;
}

.menu .nav-highlight-ghost > a {
  color: #1c1c1c;
}
.menu .nav-highlight-ghost > a:after {
  border: 1px solid #1c1c1c;
}
.menu .nav-highlight-ghost > a:hover {
  color: #00c0e1;
}
.menu .nav-highlight-ghost > a:hover:after {
  border: 1px solid #00c0e1;
}
.menu .nav-highlight-ghost.active > a {
  color: #00c0e1;
}
.menu .nav-highlight-ghost.active > a:after {
  border: 1px solid #00c0e1;
}
.menu .nav-highlight-ghost.active > a:hover {
  color: #00c0e1;
}
.menu .nav-highlight-ghost.active > a:hover:after {
  border: 1px solid #00c0e1;
}

.search-trigger, .search-trigger:hover {
  color: #4c4c4c;
}

.search-trigger:before {
  border-color: #4c4c4c;
  color: #4c4c4c;
}

.search-trigger:after {
  background: #4c4c4c;
}

.top-search.active .search-trigger:before {
  background: #4c4c4c;
}
.top-search.active .search-trigger:after {
  background: #4c4c4c;
}

.header-logo-center-menu-below .container-logo, .header-logo-left-menu-below .container-logo, .header-logo-right-menu-below .container-logo, .header-logo-left-sidebar-right .container-logo {
  background: #303C40;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.06);
}
.header-logo-center-menu-below .navbar-brand, .header-logo-left-menu-below .navbar-brand, .header-logo-right-menu-below .navbar-brand, .header-logo-left-sidebar-right .navbar-brand {
  color: #ffffff;
}
.header-logo-center-menu-below .navbar-brand:hover, .header-logo-left-menu-below .navbar-brand:hover, .header-logo-right-menu-below .navbar-brand:hover, .header-logo-left-sidebar-right .navbar-brand:hover {
  color: #ffffff;
  opacity: 0.9;
}
.header-logo-center-menu-below .logo-navbar, .header-logo-left-menu-below .logo-navbar, .header-logo-right-menu-below .logo-navbar, .header-logo-left-sidebar-right .logo-navbar {
  color: #ffffff;
}
.header-logo-center-menu-below .logo-navbar a, .header-logo-left-menu-below .logo-navbar a, .header-logo-right-menu-below .logo-navbar a, .header-logo-left-sidebar-right .logo-navbar a {
  color: #ffffff;
}
.header-logo-center-menu-below .logo-navbar .navbar-toggle .icon-bar, .header-logo-left-menu-below .logo-navbar .navbar-toggle .icon-bar, .header-logo-right-menu-below .logo-navbar .navbar-toggle .icon-bar, .header-logo-left-sidebar-right .logo-navbar .navbar-toggle .icon-bar {
  background: #ffffff;
}
.header-logo-center-menu-below .logo-navbar .logo-sidebar .sidebar-widget, .header-logo-center-menu-below .logo-navbar .logo-sidebar .sidebar-widget i, .header-logo-left-menu-below .logo-navbar .logo-sidebar .sidebar-widget, .header-logo-left-menu-below .logo-navbar .logo-sidebar .sidebar-widget i, .header-logo-right-menu-below .logo-navbar .logo-sidebar .sidebar-widget, .header-logo-right-menu-below .logo-navbar .logo-sidebar .sidebar-widget i, .header-logo-left-sidebar-right .logo-navbar .logo-sidebar .sidebar-widget, .header-logo-left-sidebar-right .logo-navbar .logo-sidebar .sidebar-widget i {
  color: #ffffff;
}
.header-logo-center-menu-below .logo-navbar .search-trigger, .header-logo-center-menu-below .logo-navbar .search-trigger:hover, .header-logo-left-menu-below .logo-navbar .search-trigger, .header-logo-left-menu-below .logo-navbar .search-trigger:hover, .header-logo-right-menu-below .logo-navbar .search-trigger, .header-logo-right-menu-below .logo-navbar .search-trigger:hover, .header-logo-left-sidebar-right .logo-navbar .search-trigger, .header-logo-left-sidebar-right .logo-navbar .search-trigger:hover {
  color: #ffffff;
}
.header-logo-center-menu-below .logo-navbar .search-trigger:before, .header-logo-left-menu-below .logo-navbar .search-trigger:before, .header-logo-right-menu-below .logo-navbar .search-trigger:before, .header-logo-left-sidebar-right .logo-navbar .search-trigger:before {
  border-color: #ffffff;
}
.header-logo-center-menu-below .logo-navbar .search-trigger:after, .header-logo-left-menu-below .logo-navbar .search-trigger:after, .header-logo-right-menu-below .logo-navbar .search-trigger:after, .header-logo-left-sidebar-right .logo-navbar .search-trigger:after {
  background: #ffffff;
}
.header-logo-center-menu-below .logo-navbar .top-search.active .search-trigger:before, .header-logo-left-menu-below .logo-navbar .top-search.active .search-trigger:before, .header-logo-right-menu-below .logo-navbar .top-search.active .search-trigger:before, .header-logo-left-sidebar-right .logo-navbar .top-search.active .search-trigger:before {
  background: #ffffff;
}
.header-logo-center-menu-below .logo-navbar .top-search.active .search-trigger:after, .header-logo-left-menu-below .logo-navbar .top-search.active .search-trigger:after, .header-logo-right-menu-below .logo-navbar .top-search.active .search-trigger:after, .header-logo-left-sidebar-right .logo-navbar .top-search.active .search-trigger:after {
  background: #ffffff;
}

.menu .sidebar-widget {
  color: #4c4c4c;
}
.menu .sidebar-widget .sidebar-header {
  color: #4c4c4c;
}
.menu .sidebar-widget i {
  color: #4c4c4c;
}

@media (min-width: 992px) {
  .transparent-header .sticky-wrapper {
    background: none;
  }

  .rtl.transparent-header #masthead .navbar-brand {
    background-position-x: 100%;
  }

  .transparent-header #masthead:not(.navbar-scrolled) {
    background: none;
    box-shadow: 0 -1px 0px 0 rgba(255, 255, 255, 0.1) inset;
  }
  .transparent-header #masthead:not(.navbar-scrolled) a {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled).search-active {
    background: #ffffff !important;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .navbar-text {
    color: #4c4c4c;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .navbar-brand {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .navbar-brand:hover {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .NSRElogo {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .NSRElogo:hover {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav > li > a {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav > li > a:before {
    background: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav > li > a:hover, .transparent-header #masthead:not(.navbar-scrolled) .nav > li > a:focus {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav > li > a:hover:before, .transparent-header #masthead:not(.navbar-scrolled) .nav > li > a:focus:before {
    background: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .navbar-toggle .icon-bar {
    background-color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav > li > a:hover,
  .transparent-header #masthead:not(.navbar-scrolled) .nav > .active > a,
  .transparent-header #masthead:not(.navbar-scrolled) .nav > .active > a:hover,
  .transparent-header #masthead:not(.navbar-scrolled) .nav > .current-menu-parent > a,
  .transparent-header #masthead:not(.navbar-scrolled) .nav > .current-menu-ancestor > a,
  .transparent-header #masthead:not(.navbar-scrolled) .nav > .current-menu-item > a,
  .transparent-header #masthead:not(.navbar-scrolled) .nav > .current-menu-item > a:hover,
  .transparent-header #masthead:not(.navbar-scrolled) .nav > .current-menu-item > a:focus,
  .transparent-header #masthead:not(.navbar-scrolled) .nav > .active > a:focus,
  .transparent-header #masthead:not(.navbar-scrolled) .nav li.dropdown.open > .dropdown-toggle,
  .transparent-header #masthead:not(.navbar-scrolled) .nav li.dropdown.active > .dropdown-toggle,
  .transparent-header #masthead:not(.navbar-scrolled) .nav li.dropdown.open.active > .dropdown-toggle {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .dropdown-menu li a {
    color: #4c4c4c;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .dropdown-menu li > a:hover, .transparent-header #masthead:not(.navbar-scrolled) .dropdown-menu .active > a, .transparent-header #masthead:not(.navbar-scrolled) .dropdown-menu .active > a:hover, .transparent-header #masthead:not(.navbar-scrolled) .dropdown-menu .active > a:focus {
    color: #00c0e1;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .search-trigger:before {
    border-color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .search-trigger:after {
    background: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .top-search.active .search-trigger:before {
    border-color: #1c1c1c;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .top-search.active .search-trigger:after {
    background: #1c1c1c;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight > a, .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight.active > a {
    color: black !important;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight > a:after, .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight.active > a:after {
    background: #ffffff !important;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight > a:hover, .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight.active > a:hover {
    color: black !important;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight > a:hover:after, .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight.active > a:hover:after {
    background: #ffffff !important;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight > a .menu-icon, .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight.active > a .menu-icon {
    color: #ffffff !important;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight-ghost > a, .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight-ghost.active > a {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight-ghost > a:after, .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight-ghost.active > a:after {
    border: 1px solid #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight-ghost > a:hover, .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight-ghost.active > a:hover {
    color: #ffffff !important;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight-ghost > a:hover:after, .transparent-header #masthead:not(.navbar-scrolled) .nav-highlight-ghost.active > a:hover:after {
    border-color: #ffffff !important;
  }
  .transparent-header #masthead:not(.navbar-scrolled).header-logo-center-menu-below .container-logo, .transparent-header #masthead:not(.navbar-scrolled).header-logo-left-menu-below .container-logo, .transparent-header #masthead:not(.navbar-scrolled).header-logo-right-menu-below .container-logo, .transparent-header #masthead:not(.navbar-scrolled).header-logo-left-sidebar-right .container-logo {
    background: none;
    box-shadow: 0 -1px 0px 0 rgba(255, 255, 255, 0.1) inset;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .navbar-brand {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .logo-navbar {
    color: #4c4c4c;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .logo-navbar .logo-sidebar .sidebar-widget, .transparent-header #masthead:not(.navbar-scrolled) .logo-navbar .logo-sidebar .sidebar-widget i {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .logo-navbar .search-trigger, .transparent-header #masthead:not(.navbar-scrolled) .logo-navbar .search-trigger:hover {
    color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .logo-navbar .search-trigger:before {
    border-color: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .logo-navbar .search-trigger:after {
    background: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .logo-navbar .top-search.active .search-trigger:before {
    background: #ffffff;
  }
  .transparent-header #masthead:not(.navbar-scrolled) .logo-navbar .top-search.active .search-trigger:after {
    background: #ffffff;
  }

  .transparent-header .top-bar {
    box-shadow: 0 -1px 0px 0 rgba(255, 255, 255, 0.1) inset;
    background: none;
    color: #4c4c4c;
  }
  .transparent-header .top-bar a {
    color: #1c1c1c;
  }
  .transparent-header .top-bar a:hover {
    color: #1c1c1c;
  }
  .transparent-header .top-bar i, .transparent-header .top-bar li i, .transparent-header .top-bar li a i {
    color: #1c1c1c;
  }
  .transparent-header .top-bar .sidebar-widget {
    border-color: rgba(0, 0, 0, 0.08);
  }
  .transparent-header .top-bar .widget_wpml_language_selector li:before {
    background: rgba(0, 0, 0, 0.08);
  }
  .transparent-header .top-bar .widget_recent_entries li, .transparent-header .top-bar .widget_recent_comments li, .transparent-header .top-bar .widget_archive li, .transparent-header .top-bar .widget_categories li, .transparent-header .top-bar .widget_meta li, .transparent-header .top-bar .widget_pages li, .transparent-header .top-bar .widget_twitter li, .transparent-header .top-bar .widget_nav_menu li, .transparent-header .top-bar .widget_rss li {
    border-color: rgba(0, 0, 0, 0.08);
  }
  .transparent-header .top-bar .search-trigger:before {
    border-color: #4c4c4c;
    color: #4c4c4c;
  }
  .transparent-header .top-bar .search-trigger:after {
    background: #4c4c4c;
  }
  .transparent-header .top-bar .top-search.active .search-trigger:before {
    background: #1c1c1c;
  }
  .transparent-header .top-bar .top-search.active .search-trigger:after {
    background: #1c1c1c;
  }
}
.menu-item-object-oxy_mega_menu {
  color: #4c4c4c;
}

.menu-item-object-oxy_mega_menu .dropdown-menu > li > ul > li > a {
  color: #4c4c4c;
}

.menu-item-object-oxy_mega_menu .dropdown-menu > li > ul > li > a:hover {
  color: #00c0e1;
}

.menu-item-object-oxy_mega_menu .dropdown-menu > li > ul > li.current-menu-item > a {
  color: #00c0e1;
}

.menu-item-object-oxy_mega_menu .dropdown-menu > li > p {
  border-bottom-color: rgba(0, 0, 0, 0.055);
}

.menu-item-object-oxy_mega_menu .dropdown-menu > li:before {
  background: rgba(0, 0, 0, 0.055);
}

#navbar-slide .form-control {
  color: #4c4c4c;
}

@media (max-width: 991px) {
  .menu .navbar-collapse {
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.055);
  }

  .menu .nav > li > a {
    border-radius: 0;
  }

  .menu .open .dropdown-menu {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0;
    box-shadow: none;
  }
  .menu .open .dropdown-menu > li > a {
    border-radius: 0;
  }
  .menu .open .dropdown-menu > li > a:hover, .menu .open .dropdown-menu > li > a:focus, .menu .open .dropdown-menu > li.active > a {
    color: #00c0e1;
  }

  .dropdown-submenu > a:after {
    border-left-color: transparent !important;
    border-top-color: #4c4c4c !important;
  }

  .menu-item-object-oxy_mega_menu .dropdown-menu > li > ul > li > a:hover {
    color: #00c0e1;
  }

  .menu-item-object-oxy_mega_menu .dropdown-menu > li:before {
    display: none;
  }

  .nav-highlight > a {
    color: #1c1c1c !important;
  }
  .nav-highlight > a:after {
    background: transparent;
  }
  .nav-highlight > a:hover {
    color: #1c1c1c !important;
  }
  .nav-highlight > a:hover:after {
    background: transparent;
  }

  .transparent-header .navbar-collapse {
    background: rgba(255, 255, 255, 0.95) !important;
  }

  .menu-sidebar {
    border-top: 1px solid rgba(255, 255, 255, 0.075);
  }
}
.pagination > li > a, .pagination > li > span {
  background: #00c0e1;
  color: #ffffff;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  background: #00c0e1;
  color: #ffffff;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
  background: #009db8;
  color: #ffffff;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  background: #009db8;
}
.pagination > .active > a i, .pagination > .active > a:hover i, .pagination > .active > a:focus i, .pagination > .active > span i, .pagination > .active > span:hover i, .pagination > .active > span:focus i {
  color: #ffffff;
}

.pagination > li:first-child i, .pagination > li:last-child i {
  color: #ffffff;
}

.pagination > .disabled > span, .pagination > .disabled span:hover, .pagination > .disabled > a, .pagination > .disabled a:hover {
  background: #00c0e1;
  color: #ffffff;
}
.pagination > .disabled > span i, .pagination > .disabled span:hover i, .pagination > .disabled > a i, .pagination > .disabled a:hover i {
  color: #ffffff;
}

.box-inner {
  background-color: rgba(0, 0, 0, 0.1);
}

.pager > li > a {
  background: #00c0e1;
  color: #ffffff;
}
.pager > li > a i {
  color: #ffffff;
}

.pager li > a:hover, .pager li > a:focus {
  background-color: #009db8;
  color: #ffffff;
}

.tooltip-inner {
  background-color: #2e3333;
  color: #ffffff;
}

.tooltip.in {
  opacity: 1;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #2e3333;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #2e3333;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #2e3333;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #2e3333;
}

.jumbotron {
  background: rgba(0, 0, 0, 0.1);
}

.progress {
  border-radius: 0px;
}
.progress .progress-bar {
  border-radius: 0px;
}

.progress-bar-primary {
  background: #00c0e1;
  box-shadow: none;
}
.progress-bar-primary span {
  color: #ffffff;
}

.progress-bar-primary.progress-striped .progress-bar, .progress-striped .progress-bar-primary {
  background-color: #00c0e1;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  background-size: 40px 40px;
}

.badge {
  background: #2e3333;
  color: #ffffff;
}

.nav-tabs {
  border-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs > li > a {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.nav-tabs > li > a:hover {
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #ffffff;
}
.nav-tabs > li > a:hover {
  background: none;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active a:focus {
  border-top-color: rgba(0, 0, 0, 0.1);
  border-left-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
}

.tab-content {
  background: #ffffff;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tabs-below .nav-tabs {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.tabs-below .nav-tabs > li > a {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.tabs-below .nav-tabs > li.active > a, .tabs-below .nav-tabs > li > a:hover {
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-top: 0;
}
.tabs-below .tab-content {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.nav-pills > li > a .badge {
  background: #2e3333;
  color: #ffffff;
}

.nav-pills > li.active > a, .nav-pills > li.is-active > a, .nav-pills > li.active > a:hover, .nav-pills > li.is-active > a:hover, .nav-pills > li > a:hover, .nav-pills > li > a:focus {
  background: #2e3333;
  color: #ffffff;
}
.nav-pills > li.active > a .badge, .nav-pills > li.is-active > a .badge, .nav-pills > li.active > a:hover .badge, .nav-pills > li.is-active > a:hover .badge, .nav-pills > li > a:hover .badge, .nav-pills > li > a:focus .badge {
  background: #ffffff;
  color: #2e3333;
}
.nav-pills > li.active > a:hover, .nav-pills > li.is-active > a:hover, .nav-pills > li.active > a:hover:hover, .nav-pills > li.is-active > a:hover:hover, .nav-pills > li > a:hover:hover, .nav-pills > li > a:focus:hover {
  color: #ffffff;
}

.panel, .well {
  background: rgba(0, 0, 0, 0.1);
}

.panel-primary {
  border-color: rgba(0, 0, 0, 0.1);
}

.panel-primary .panel-heading {
  background: #00c0e1;
}
.panel-primary .panel-heading a, .panel-primary .panel-heading .panel-title {
  color: #ffffff;
  text-decoration: none;
}

.panel-primary > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #007f95;
  background: transparent;
}

.panel-footer {
  background: #00d6fb;
  border-color: #00aac8;
}

.panel .table {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1) inset;
}
.panel .table th {
  background: rgba(0, 0, 0, 0.1);
  color: #1c1c1c;
}

.portfolio-title {
  background: #00c0e1;
  color: #ffffff;
}

.portfolio-nav svg {
  stroke: #4c4c4c;
}

.social-icons li a {
  background-color: #2e3333;
}
.social-icons li a i {
  color: #ffffff;
}
.social-icons li a:not([data-iconcolor]):hover {
  background-color: rgba(46, 51, 51, 0.8);
}

.social-simple li a i {
  color: #4c4c4c;
}

.post-share .social-simple li a i {
  color: #959494;
}

.social-simple li a:not([data-iconcolor]):hover i {
  color: #00c0e1;
}

.divider-border-inner {
  background-color: #00c0e1;
}

#infscr-loading {
  background: #2e3333;
  color: #ffffff;
}

.scroll-to i {
  color: #00c0e1;
}
.scroll-to:hover i {
  color: #08b7d4;
}

.map-marker-label {
  background: #ffffff;
  color: #4c4c4c;
}

.features-list-icon {
  background: rgba(0, 0, 0, 0.1);
}

.ui-datepicker {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.ui-datepicker-header {
  background-color: rgba(0, 0, 0, 0.02);
  color: #3c3c3c;
}

.ui-datepicker-today {
  background-color: #00c0e1;
}
.ui-datepicker-today a, .ui-datepicker-today a:hover {
  color: #ffffff;
}

.ui-datepicker-calendar thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
}

.date-wrapper:after {
  color: #3c3c3c;
}

.pace-overlay {
  background: #ffffff;
}

.pace-dot .pace-progress-inner {
  background: #00c0e1;
}

.pace-minimal .pace .pace-progress {
  background: #00c0e1;
}

.pace-counter .pace .pace-progress:after {
  color: #00c0e1;
}

.pace-logo .pace .pace-progress {
  background: #ffffff;
}

.pace-logo .pace:before {
  background: #00c0e1;
}

.pricing-head, .pricing-body {
  background: #ffffff;
}

.pricing-price {
  background: #00c0e1;
  color: #ffffff;
}

.pricing-list li {
  border-color: rgba(0, 0, 0, 0.1);
}

.pricing-item-list li h3 {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.pricing-item-list li strong {
  background-color: #00c0e1;
  color: #ffffff;
}
.pricing-item-list li.featured:before {
  border-color: #00c0e1;
}

.col-border-top {
  border-top-color: rgba(0, 0, 0, 0.1);
}

.col-border-right {
  border-right-color: rgba(0, 0, 0, 0.1);
}

.col-border-bottom {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.col-border-left {
  border-left-color: rgba(0, 0, 0, 0.1);
}

.tag-list span {
  background: #00c0e1;
  color: #ffffff;
}

.bullet-nav li a {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 0px 3px rgba(46, 51, 51, 0.5);
}
.bullet-nav li a.active {
  background-color: rgba(46, 51, 51, 0.9);
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
}

.mejs-audio {
  background-color: #00c0e1;
  color: #ffffff;
}

.mejs-container .mejs-controls .mejs-time span {
  color: #ffffff;
}

.mejs-controls .mejs-button button:hover {
  background-color: transparent;
}

.mejs-controls .mejs-time-rail .mejs-time-loaded {
  background: #ffffff;
}

.mejs-controls .mejs-time-rail .mejs-time-current, .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
  background: #ffffff;
}

.cd-headline.type .cd-words-wrapper.selected {
  background-color: #1c1c1c;
}

.text-light.cd-headline.type .cd-words-wrapper.selected {
  background-color: #ffffff;
}

.cd-headline.type .cd-words-wrapper.selected b {
  color: #e3e3e3;
}

.text-light.cd-headline.type .cd-words-wrapper.selected b {
  color: black;
}

.cd-headline.loading-bar .cd-words-wrapper::after {
  background: #1c1c1c;
}

.text-light.cd-headline.loading-bar .cd-words-wrapper::after {
  background: #ffffff;
}

.cd-headline.clip .cd-words-wrapper::after {
  background-color: #1c1c1c;
}

.text-light.cd-headline.clip .cd-words-wrapper::after {
  background-color: #ffffff;
}

.cd-headline.type .cd-words-wrapper::after {
  background-color: #1c1c1c;
}

.text-light.cd-headline.type .cd-words-wrapper::after {
  background-color: #ffffff;
}

#cd-timeline::before {
  background: rgba(0, 0, 0, 0.1);
}

.cd-timeline-content {
  background-color: #ffffff;
}

.cd-timeline-content::before {
  border-right-color: #ffffff;
}

.cd-timeline-img {
  background-color: #00c0e1;
}
.cd-timeline-img i {
  color: #ffffff;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-content::before {
    border-color: transparent;
    border-left-color: #ffffff;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    border-color: transparent;
    border-right-color: #ffffff;
  }
}
.post:after {
  background: rgba(0, 0, 0, 0.1);
}

.post-more-link i {
  color: #00c0e1;
}

.media-inner:after {
  background: rgba(0, 0, 0, 0.1);
}

.post .author-info {
  border-color: rgba(0, 0, 0, 0.1);
}

.post-list > div {
  border-color: rgba(0, 0, 0, 0.1);
}

.breadcrumb-blog a, .breadcrumb-blog .active {
  color: #4c4c4c;
}
.breadcrumb-blog a:hover {
  opacity: 0.8;
}
.breadcrumb-blog > li + li:before {
  color: #4c4c4c;
}

.container-author {
  background: rgba(0, 0, 0, 0.1);
}

.gform_wrapper .gsection {
  border-color: rgba(0, 0, 0, 0.1);
}

.gf_progressbar {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 0px;
}

.gf_progressbar_percentage {
  background: #00c0e1;
  color: #ffffff;
}

.gf_page_steps {
  border-color: rgba(0, 0, 0, 0.1);
}

.sidebar-widget .post-icon {
  background: rgba(0, 0, 0, 0.1);
}

.widget_recent_entries li, .widget_recent_entries .sub-menu, .widget_recent_comments li, .widget_recent_comments .sub-menu, .widget_archive li, .widget_archive .sub-menu, .widget_categories li, .widget_categories .sub-menu, .widget_meta li, .widget_meta .sub-menu, .widget_pages li, .widget_pages .sub-menu, .widget_twitter li, .widget_twitter .sub-menu, .widget_nav_menu li, .widget_nav_menu .sub-menu, .widget_rss li, .widget_rss .sub-menu {
  border-color: rgba(0, 0, 0, 0.1);
}
.widget_recent_entries small, .widget_recent_comments small, .widget_archive small, .widget_categories small, .widget_meta small, .widget_pages small, .widget_twitter small, .widget_nav_menu small, .widget_rss small {
  color: #4c4c4c;
}

.widget_tag_cloud ul a {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  color: #4c4c4c;
}
.widget_tag_cloud ul a:hover {
  box-shadow: 0px 0px 0px 1px #00c0e1;
  color: #00c0e1;
}

.widget_calendar tbody a {
  background: #2e3333;
  color: #ffffff;
}
.widget_calendar tbody a:hover {
  background: #1d2020;
  color: #ffffff;
}
.widget_calendar tfoot {
  background: rgba(0, 0, 0, 0.1);
}

.widget_nav_menu .sub-menu li:before {
  color: rgba(0, 0, 0, 0.1);
}

.widget_oxy_twitter li:before {
  color: #2e3333;
}

.top-search form {
  background-color: #ffffff;
}
.top-search input {
  background: none;
  color: #4c4c4c;
}
.top-search input:focus {
  box-shadow: none;
}
.top-search input:-moz-placeholder {
  color: #4c4c4c;
}
.top-search input::-moz-placeholder {
  color: #4c4c4c;
}
.top-search input:-ms-input-placeholder {
  color: #4c4c4c;
}
.top-search input::-webkit-input-placeholder {
  color: #4c4c4c;
}

.top-bar .top-search form {
  background-color: #f0f0f0;
}
.top-bar .top-search input {
  background: none;
  color: #6c6c6c;
}
.top-bar .top-search input:focus {
  box-shadow: none;
}
.top-bar .top-search input:-moz-placeholder {
  color: #6c6c6c;
}
.top-bar .top-search input::-moz-placeholder {
  color: #6c6c6c;
}
.top-bar .top-search input:-ms-input-placeholder {
  color: #6c6c6c;
}
.top-bar .top-search input::-webkit-input-placeholder {
  color: #6c6c6c;
}

.logo-sidebar .top-search form {
  background-color: #303C40;
}
.logo-sidebar .top-search input {
  background: none;
  color: #ffffff;
}
.logo-sidebar .top-search input:focus {
  box-shadow: none;
}
.logo-sidebar .top-search input:-moz-placeholder {
  color: #ffffff;
}
.logo-sidebar .top-search input::-moz-placeholder {
  color: #ffffff;
}
.logo-sidebar .top-search input:-ms-input-placeholder {
  color: #ffffff;
}
.logo-sidebar .top-search input::-webkit-input-placeholder {
  color: #ffffff;
}

.top-bar {
  background: #f0f0f0;
  color: #6c6c6c;
}
.top-bar a {
  color: #1c1c1c;
}
.top-bar a:hover {
  color: #3c3c3c;
}
.top-bar i, .top-bar li i, .top-bar li a i {
  color: #1c1c1c;
}
.top-bar .sidebar-widget {
  border-color: rgba(0, 0, 0, 0.08);
}
.top-bar .widget_wpml_language_selector li:before {
  background: rgba(0, 0, 0, 0.08);
}
.top-bar .widget_recent_entries li, .top-bar .widget_recent_comments li, .top-bar .widget_archive li, .top-bar .widget_categories li, .top-bar .widget_meta li, .top-bar .widget_pages li, .top-bar .widget_twitter li, .top-bar .widget_nav_menu li, .top-bar .widget_rss li {
  border-color: rgba(0, 0, 0, 0.08);
}
.top-bar .search-trigger:before {
  border-color: #6c6c6c;
  color: #6c6c6c;
}
.top-bar .search-trigger:after {
  background: #6c6c6c;
}

.section-upper-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#footer {
  background-color: #005B99;
  color: #005B99;
}
#footer h1, #footer h2, #footer h3, #footer h4, #footer h5, #footer h6, #footer strong {
  color: #ffffff;
}
#footer a {
  color: #ffffff;
  font-weight: 400;
  text-decoration: underline;
}
#footer a:hover {
  color: #dddddd;
}
#footer i {
  color: inherit;
}
#footer small {
  color: #005B99;
}
#footer .widget_recent_entries small, #footer .widget_recent_comments small, #footer .widget_archive small, #footer .widget_categories small, #footer .widget_meta small, #footer .widget_pages small, #footer .widget_twitter small, #footer .widget_nav_menu small, #footer .widget_rss small {
  color: #005B99;
}
#footer .widget_recent_entries li, #footer .widget_recent_entries .sub-menu, #footer .widget_recent_comments li, #footer .widget_recent_comments .sub-menu, #footer .widget_archive li, #footer .widget_archive .sub-menu, #footer .widget_categories li, #footer .widget_categories .sub-menu, #footer .widget_meta li, #footer .widget_meta .sub-menu, #footer .widget_pages li, #footer .widget_pages .sub-menu, #footer .widget_twitter li, #footer .widget_twitter .sub-menu, #footer .widget_nav_menu li, #footer .widget_nav_menu .sub-menu, #footer .widget_rss li, #footer .widget_rss .sub-menu {
  border-color: rgba(255, 255, 255, 0.1);
}
#footer .widget_tag_cloud ul a {
  box-shadow: 0px 0px 0px 1px #005B99;
  color: #005B99;
}
#footer .widget_tag_cloud ul a:hover {
  box-shadow: 0px 0px 0px 1px #ffffff;
  color: #ffffff;
}
#footer .widget_calendar tbody a {
  background: #ffffff;
  color: #005B99;
}
#footer .widget_calendar tbody a:hover {
  background: #ededed;
  color: #005B99;
}
#footer .widget_calendar tfoot {
  background: rgba(255, 255, 255, 0.1);
}
#footer .widget_nav_menu .sub-menu li:before {
  color: rgba(255, 255, 255, 0.1);
}
#footer .widget_oxy_twitter li:before {
  color: #ffffff;
}
#footer .star-rating {
  color: #005B99;
}

.subfooter {
  background-color: #1c1c1c;
  color: #eeeeee;
}
.subfooter h1, .subfooter h2, .subfooter h3, .subfooter h4, .subfooter h5, .subfooter h6, .subfooter strong {
  color: #ffffff;
}
.subfooter a {
  color: #ffffff;
}
.subfooter a:hover {
  color: #ffffff;
}
.subfooter i {
  color: inherit;
}
.subfooter small {
  color: #eeeeee;
}
.subfooter .widget_recent_entries small, .subfooter .widget_recent_comments small, .subfooter .widget_archive small, .subfooter .widget_categories small, .subfooter .widget_meta small, .subfooter .widget_pages small, .subfooter .widget_twitter small, .subfooter .widget_nav_menu small, .subfooter .widget_rss small {
  color: #eeeeee;
}
.subfooter .widget_recent_entries li, .subfooter .widget_recent_entries .sub-menu, .subfooter .widget_recent_comments li, .subfooter .widget_recent_comments .sub-menu, .subfooter .widget_archive li, .subfooter .widget_archive .sub-menu, .subfooter .widget_categories li, .subfooter .widget_categories .sub-menu, .subfooter .widget_meta li, .subfooter .widget_meta .sub-menu, .subfooter .widget_pages li, .subfooter .widget_pages .sub-menu, .subfooter .widget_twitter li, .subfooter .widget_twitter .sub-menu, .subfooter .widget_nav_menu li, .subfooter .widget_nav_menu .sub-menu, .subfooter .widget_rss li, .subfooter .widget_rss .sub-menu {
  border-color: rgba(255, 255, 255, 0.1);
}
.subfooter .widget_tag_cloud ul a {
  box-shadow: 0px 0px 0px 1px #eeeeee;
  color: #eeeeee;
}
.subfooter .widget_tag_cloud ul a:hover {
  box-shadow: 0px 0px 0px 1px #ffffff;
  color: #ffffff;
}
.subfooter .widget_calendar tbody a {
  background: #ffffff;
  color: #1c1c1c;
}
.subfooter .widget_calendar tbody a:hover {
  background: #ededed;
  color: #1c1c1c;
}
.subfooter .widget_calendar tfoot {
  background: rgba(255, 255, 255, 0.1);
}
.subfooter .widget_nav_menu .sub-menu li:before {
  color: rgba(255, 255, 255, 0.1);
}
.subfooter .widget_oxy_twitter li:before {
  color: #ffffff;
}
.subfooter .star-rating {
  color: #eeeeee;
}

.product-title a {
  color: #1c1c1c;
}
.product-title a:hover {
  color: #00c0e1;
}

.add-to-cart-button i {
  color: #4c4c4c;
}
.add-to-cart-button.loading:before {
  color: #4c4c4c;
}
.add-to-cart-button.added i {
  color: #00c0e1;
}
.add-to-cart-button.added:before {
  color: #00c0e1;
}

.product-image-overlay {
  color: #ffffff;
  background-color: rgba(55, 147, 155, 0.9);
}
.product-image-overlay h4, .product-image-overlay .star-rating {
  color: #ffffff;
}

.product-categories {
  text-transform: none;
}

.button {
  background: #00c0e1;
  color: #ffffff;
}
.button:hover {
  background: rgba(0, 192, 225, 0.9);
  color: #ffffff;
}

.section-commerce .breadcrumb {
  background: none;
  color: #4c4c4c;
}
.section-commerce .breadcrumb a {
  color: #00c0e1;
}
.section-commerce .breadcrumb > li + li:before {
  color: #00c0e1;
}

.onsale {
  background: #00c0e1;
  color: #ffffff;
}

.price {
  color: #1c1c1c;
}

.page-numbers a, .page-numbers span {
  color: #ffffff;
}
.page-numbers li {
  background: #00c0e1;
}
.page-numbers .current, .page-numbers li a:hover {
  background: #00aac8;
  color: #f2f2f2;
}

#reviews input[type="submit"] {
  background: #00c0e1;
  color: #ffffff;
}

.product-category h3 {
  background: rgba(55, 147, 155, 0.9);
  color: #ffffff;
}
.product-category h3 small {
  color: #ffffff;
}

.description p {
  font-size: 21px;
  font-weight: 400;
  line-height: 1.75em;
}

.product-nav a i {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.02);
  color: #3c3c3c;
}
.product-nav a:hover i {
  box-shadow: 0px 0px 0px 1px #00c0e1;
  color: #00c0e1;
}

.quantity input {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1) inset;
  background: rgba(0, 0, 0, 0.02);
  color: #3c3c3c;
}
.quantity input:hover {
  box-shadow: 0px 0px 0px 1px #00c0e1 inset;
  color: #00c0e1;
}
.quantity input[type="number"] {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1) inset, 0px -1px 0px rgba(0, 0, 0, 0.1) inset;
}

.entry-summary ul.social-icons li a {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.entry-summary ul.social-icons li a i {
  color: #3c3c3c;
}
.entry-summary ul.social-icons li a:hover {
  background: transparent;
  border: 1px solid #00c0e1;
}
.entry-summary ul.social-icons li a:hover i {
  color: #00c0e1;
}

.product-images figcaption {
  box-shadow: none;
  background: rgba(55, 147, 155, 0.9);
}
.product-images figcaption h4 a {
  color: #ffffff;
}

.order_details {
  background: rgba(0, 0, 0, 0.1);
}
.order_details li {
  border-color: rgba(26, 26, 26, 0.1);
}

.product-mini {
  color: #4c4c4c;
}
.product-mini a {
  color: #1c1c1c;
}

.mini-cart-count {
  background-color: #00c0e1;
  color: #ffffff;
}

.mini-cart-overview ul li {
  border-bottom-color: rgba(0, 0, 0, 0.055);
}

.product-details-heading, .mini-cart-overview .product-details-heading a {
  color: #1c1c1c;
}

.mini-cart-overview .buttons a:first-child {
  box-shadow: 0px 0px 0px 1px #4c4c4c inset;
  color: #4c4c4c;
}

.mini-cart-overview .buttons a:last-child {
  background: #00c0e1;
  color: #ffffff;
}

.woocommerce-invalid input,
input.woocommerce-invalid,
.woocommerce-invalid textarea,
textarea.woocommerce-invalid {
  border-color: #e85543;
}

.shipping_calculator {
  font-family: "Open Sans";
  font-weight: 600;
}

.chosen-single, .chosen-drop {
  background: rgba(0, 0, 0, 0.02) !important;
  border-radius: 0px !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: #3c3c3c !important;
}

.product-remove i {
  color: #00c0e1;
}

.widget_product_tag_cloud a {
  border-radius: 0px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  color: #4c4c4c;
}
.widget_product_tag_cloud a:hover {
  box-shadow: 0px 0px 0px 1px #00c0e1;
  color: #00c0e1;
}

.product_list_widget li, .widget_product_categories li, .widget_product_categories .children, .widget_layered_nav li {
  border-color: rgba(0, 0, 0, 0.1);
}
.product_list_widget li a, .widget_product_categories li a, .widget_product_categories .children a, .widget_layered_nav li a {
  font-family: "Open Sans";
  color: #1c1c1c;
  font-weight: 600;
  text-transform: none;
}
.product_list_widget li a:hover, .widget_product_categories li a:hover, .widget_product_categories .children a:hover, .widget_layered_nav li a:hover {
  color: #00c0e1;
}

.star-rating {
  color: #00c0e1;
}

.widget_price_filter .ui-slider .ui-slider-range {
  background: rgba(0, 0, 0, 0.1);
}
.widget_price_filter .ui-slider .ui-slider-handle {
  background: #2e3333;
}

.widget_product_search form:after {
  color: #ffffff;
}

#footer .widget_product_tag_cloud a {
  box-shadow: 0px 0px 0px 1px #005B99;
  color: #005B99;
}
#footer .widget_product_tag_cloud a:hover {
  box-shadow: 0px 0px 0px 1px #ffffff;
  color: #ffffff;
}
#footer .product_list_widget li, #footer .widget_product_categories li, #footer .widget_product_categories .children, #footer .widget_layered_nav li {
  border-color: rgba(255, 255, 255, 0.1);
}
#footer .product_list_widget li a, #footer .widget_product_categories li a, #footer .widget_product_categories .children a, #footer .widget_layered_nav li a {
  color: #ffffff;
}
#footer .product_list_widget li a:hover, #footer .widget_product_categories li a:hover, #footer .widget_product_categories .children a:hover, #footer .widget_layered_nav li a:hover {
  color: #dddddd;
}
#footer .star-rating {
  color: #005B99;
}
#footer .widget_price_filter .ui-slider .ui-slider-range {
  background: rgba(255, 255, 255, 0.1);
}
#footer .widget_price_filter .ui-slider .ui-slider-handle {
  background: #005B99;
}
#footer .widget_product_search form:after {
  color: #005B99;
}

.bbp-forums-list li {
  border-left-color: #00c0e1;
}

.bbp-topic-freshness-author:after {
  background: #00c0e1;
}

.bbp-topic-permalink i {
  color: #00c0e1;
}

.panel-bbpress {
  background: #ffffff;
}

.panel-bbpress .panel-heading {
  border-color: #00c0e1;
}

.panel-bbpress .panel-body {
  border-color: rgba(0, 0, 0, 0.1);
}

.panel-bbpress .panel-warning {
  color: #00c0e1;
  border-color: rgba(0, 0, 0, 0.1);
}

.panel-bbpress .bbp-template-notice {
  border-color: rgba(0, 0, 0, 0.1);
}

.panel-bbpress .panel-footer {
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}

.label-bbpress {
  background: transparent;
  color: #00c0e1;
}

.bbp-reply-content {
  border-color: rgba(0, 0, 0, 0.1);
}
.bbp-reply-content .media-heading {
  background: rgba(13, 13, 13, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}

.bbp_author_details img {
  background: #ffffff;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1);
}
.bbp_author_details:after {
  background: rgba(0, 0, 0, 0.1);
}

.bbp_author_details:hover img {
  box-shadow: 0px 0px 0px 4px #00c0e1;
}

.bbpress-header .breadcrumb a, .bbpress-header .breadcrumb .active {
  color: #4c4c4c;
}
.bbpress-header .breadcrumb a:hover {
  color: rgba(76, 76, 76, 0.8);
}

.bbp-topic-tags a {
  background: #00c0e1;
  color: #ffffff;
}
.bbp-topic-tags a:hover {
  background: rgba(0, 192, 225, 0.9);
}

.bbp-logged-in {
  background: rgba(0, 0, 0, 0.1);
}
.bbp-logged-in .button, .bbp-logged-in .button:hover {
  color: #ffffff;
}

.post-count {
  background: #00c0e1;
  color: #ffffff;
}

#wpfc_sermon_wrap {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
#wpfc_sermon_wrap .sermon-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.text-light {
  color: #ffffff;
}
.text-light i {
  color: #ffffff;
}

h1.text-light,
h2.text-light,
h3.text-light,
h4.text-light,
h5.text-light,
h6.text-light,
.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6 {
  color: #ffffff;
}

.text-light a, a.text-light {
  color: #ffffff;
}
.text-light a:hover, .text-light a:focus, a.text-light:hover, a.text-light:focus {
  color: rgba(255, 255, 255, 0.95);
}

.text-light blockquote, blockquote.text-light {
  color: #ffffff;
}
.text-light blockquote small, .text-light blockquote footer, .text-light blockquote cite, blockquote.text-light small, blockquote.text-light footer, blockquote.text-light cite {
  color: #ffffff;
}
.text-light blockquote:before, blockquote.text-light:before {
  color: rgba(255, 255, 255, 0.7);
}

.text-light.btn-link, .text-light .btn-link {
  box-shadow: 0px 0px 0px 1px #ffffff inset !important;
}
.text-light.btn-link i, .text-light .btn-link i {
  color: #ffffff;
}

.text-light.btn-link:hover, .text-light .btn-link:hover, .text-light.btn-link:focus, .text-light .btn-link:focus {
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.9) inset !important;
  background: none;
}

.text-light.btn-primary, .text-light .btn-primary {
  background: #ffffff !important;
  color: #4c4c4c !important;
}
.text-light.btn-primary:hover, .text-light.btn-primary:focus, .text-light .btn-primary:hover, .text-light .btn-primary:focus {
  background: rgba(255, 255, 255, 0.9) !important;
}

.text-light small, .text-light .small {
  color: #ffffff;
}

.text-light.btn-primary:hover, .text-light .btn-primary:hover {
  opacity: 1;
}

.text-light .breadcrumb-blog a, .text-light .breadcrumb-blog .active {
  color: #ffffff;
}
.text-light .breadcrumb-blog > li + li:before {
  color: #ffffff;
}

.text-light.social-icons li a {
  background: #ffffff !important;
}
.text-light.social-icons li a i {
  color: #4c4c4c !important;
}

.text-light.social-simple li a {
  background: none !important;
}
.text-light.social-simple li a i {
  color: #ffffff !important;
}

.text-light .portfolio-nav svg {
  stroke: #ffffff;
}

.text-light.scroll-to i, .text-light .scroll-to i {
  color: #ffffff;
}
.text-light.scroll-to:hover, .text-light .scroll-to:hover {
  opacity: 0.9;
}

.text-light #wpfc_sermon_wrap {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.text-light #wpfc_sermon_wrap .sermon-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

